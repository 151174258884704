////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-thirdColor {
  background-color: rgb(255, 255, 255);
}
.bg-fourthColor {
  background-color: rgb(49, 93, 127);
}
.bg-fifthColor {
  background-color: rgb(0, 0, 0);
}
.bg-sixthColor {
  background-color: rgb(49, 93, 127);
}
.bg-seventhColor {
  background-color: rgb(255, 255, 255);
}
